import React, {useState} from 'react';
// import Image from '../ResuableImageWrapper';
// import parse from 'html-react-parser'
import LottieWrapVideo from '../../lottie/lottieWrapVideo';
import CountUp, {startAnimation} from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_text_stat_bg_image.scss";
import "../../../sass/components/reuseable_components/_stat_block.scss";

export default function CaseStudyTextStatisticBgImage(data, {isInView}) {
  const [didViewCountUp, setDidViewCountUp] = useState(false)
  const [isInview, setIsInview] = useState(false);
 

  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
    setIsInview(isVisible)
  }
  let classList = [
    'text_stat_bg_image',
    'contains-custom-colour'
  ]; 
  
  const removeBottomMargin = data?.removeBottomMargin;

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  return (

    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} scrollCheck={true} delayedCall={true}  active={!isInview}  onChange={onVisibilityChange}>
    {({isVisible}) =>
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}>
        <div className="wrapper clearfix">    
          <div className={`stat-block ${isInview ? 'is_inview' : ''}`}>
            <div className="stat-info">
              <span className="stat">         
                <CountUp 
                className="number" 
                start={0} 
                duration={5} 
                suffix={data.animatedStatisticWArrow.symbol} 
                end={didViewCountUp === true ? data.animatedStatisticWArrow.number : 0}
                >
              </CountUp>
              </span>
              <div className="label">{data.animatedStatisticWArrow.label}</div>
            </div>
            <div className={`stat-graphic ${isInview ? 'is_inview' : ''}`}>
              
              <LottieWrapVideo animation="arrow" checkIsInview={true} isInview={isInview ? true : false}></LottieWrapVideo> 
              <div className="pounds"><span>£</span><span>£</span><span>£</span></div>
            </div>
          </div>
        </div>
      </section>
    }
  </VisibilitySensor>
    
  )
}
